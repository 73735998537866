<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        {{t('Communications')}}
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch1"
          v-model="localOptions.notifications.new_releases.app"
          switch
          inline
        >
          <span>{{ t('Notify when new release has been released')}}</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch2"
          v-model="localOptions.notifications.new_sat_catalog.app"
          switch
          inline
        >
          <span>{{ t('Notify when SAT releases or update a new catalog') }}</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch3"
          v-model="localOptions.notifications.new_sat_fiscal_review.app"
          switch
          inline
        >
          <span>{{ t('Notify when SAT releases new fiscal revision') }}</span>
        </b-form-checkbox>
      </b-col>

      <!-- application switch -->
      <h6 class="section-label mx-1 mt-2">
        {{ t('Application') }}
      </h6>
      <b-col
        cols="12"
        class="mt-1 mb-2"
      >
        <b-form-checkbox
          id="accountSwitch4"
          v-model="localOptions.notifications.tokens_under_10percent.app"
          switch
          inline
        >
          <span>{{ t('Notify when your tokens are under 10%') }}</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch5"
          v-model="localOptions.notifications.csd_near_to_due.app"
          switch
          inline
        >
          <span>{{ t('Notify when your Certificate is going to due (1 month)') }}</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch6"
          v-model="localOptions.notifications.users_emmit_invoices.app"
          switch
          inline
        >
          <span>{{ t('Notify when a user emmit invoices on your behalf') }}</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch7"
          v-model="localOptions.notifications.users_cancel_invoices.app"
          switch
          inline
        >
          <span>{{ t('Notify when a user cancel invoices on your behalf') }}</span>
        </b-form-checkbox>
      </b-col>
      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="updateCommunications"
        >
          Save changes
        </b-button>
        <!--<b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mt-1"
          variant="outline-secondary"
        >
          Cancel
        </b-button>-->
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  directives: {
    Ripple,
  },
  props: {
    communicationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.communicationData)),
    }
  },
  watch: {
    communicationData (v) {
      this.localOptions = JSON.parse(JSON.stringify(v))
    }
  },
  methods: {
    updateCommunications() {
      let communications = {
        notifications: {
          new_releases : {
            app: this.localOptions.notifications.new_releases.app,
            sms: false,
            email: false,
          },
          new_sat_catalog : {
            app: this.localOptions.notifications.new_sat_catalog.app,
            sms: false,
            email: false,
          },
          new_sat_fiscal_review : {
            app: this.localOptions.notifications.new_sat_fiscal_review.app,
            sms: false,
            email: false,
          },
          tokens_under_10percent : {
            app: this.localOptions.notifications.tokens_under_10percent.app,
            sms: false,
            email: false,
          },
          csd_near_to_due : {
            app: this.localOptions.notifications.csd_near_to_due.app,
            sms: false,
            email: false,
          },
          users_emmit_invoices : {
            app: this.localOptions.notifications.users_emmit_invoices.app,
            sms: false,
            email: false,
          },
          users_cancel_invoices : {
            app: this.localOptions.notifications.users_cancel_invoices.app,
            sms: false,
            email: false,
          },
        }
      }

      console.group('fg.views.settings.communications')
      console.log('request',communications)
        

      this.$http.post('/account/communications/update', { communications })
      .then(response => {
        console.log('response',response)
        console.groupEnd()

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Business Data Updated'),
            icon: 'StarIcon',
            variant: 'success',
            text: this.$t('Communication preferences was succesfully updated.'),
          },
        })
      })
      .catch(error => {
        console.error('error',error)
        console.groupEnd()
      })
    }
  }
}
</script>
