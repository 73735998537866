<template>
  <b-card>
    <!-- form -->  
    <b-form>
      <b-row>
        <!-- zip -->
        <b-col md="2">
          <validation-provider
            #default="validationContext"
            name="ZIP"
            rules="required|min:5"
          >
          <b-form-group
            label-for="address-zip"
            :label="t('Zip')"
             :invalid-feedback="t('zipr')"
          >
            <b-form-input
              id="zip"
              v-model="localOptions.zip"
              :state="getValidationState(validationContext)"
              trim
              type="number"
              :formatter="fmt5num"              
              :placeholder="t('Zip')"
              @change="checkZip(localOptions.zip)"
            />
          </b-form-group>
          </validation-provider>
        </b-col>
        <!--/ zip -->

        <b-col md="10">
          
        </b-col>

        <!-- Country -->
        <b-col md="6">
          <b-form-group
            label-for="countryList"
            :label="t('Country')"
          >
            <v-select
              id="countryList"
              disabled
              v-model="localOptions.country"
              text-field="v"
              label="v"
              :options="countryOption"
            />
          </b-form-group>
        </b-col>

        <!-- State -->
        <b-col md="6">
          <b-form-group
            label-for="stateList"
            :label="t('State')"
          >
            <v-select
              id="stateList"
              disabled
              v-model="localOptions.state"
              text-field="v"
              label="v"
              :options="stateOption"
            />
          </b-form-group>
        </b-col>

        <!-- Town -->
        <b-col md="6">
          <b-form-group
            label-for="townList"
            :label="t('Town')"
          >
            <v-select
              id="townList"
              disabled
              v-model="localOptions.town"
              text-field="v"
              label="v"
              :options="townOption"
            />
          </b-form-group>
        </b-col>

        <!-- Suburb -->
        <b-col md="6">
          <b-form-group
            label-for="suburbList"
            :label="t('Suburb')"
          >
            <v-select
              id="suburbList"
              v-model="localOptions.suburb"
              text-field="v"
              label="v"
              :options="suburbOption"
            />
          </b-form-group>
        </b-col>

        <!-- Street -->
        <b-col md="12">
          <b-form-group
            label-for="address-street"
            :label="t('Street')"
          >
            <b-form-input
              id="street"
              v-model="localOptions.street"
              
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="updateAddress"
            :disabled="!isSaveEnabled"
          >
            {{ t('Save') }}
          </b-button>
<!-- ILL: 0800222 SE ELIMINA BOTON DE RESET
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            {{ t('Reset') }}
          </b-button>-->
        </b-col>
      </b-row>
    </b-form>
  
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormFile, BRow, BCol, BCard, BFormTextarea, BInputGroup, BInputGroupAppend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, catalog } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    flatPickr,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    
  },
  directives: {
    Ripple,
  },
  props: {
    mainAddressData: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      email,
      catalog,
      suburbOption: [],
      townOption: [],
      stateOption: [],
      stimeOption: null,
      wtimeOption: null,
      countryOption: [{k:'MX',v:'México'}],

      localOptions: JSON.parse(JSON.stringify(this.mainAddressData)),

    }
  },
  watch: {
    mainAddressData (v) {
      this.localOptions = JSON.parse(JSON.stringify(v))
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isSaveEnabled: {
      get() {
        return this.localOptions.zip !== '' &&
               this.localOptions.suburb.k !== '' /**&&
               this.localOptions.street !== ''**/
      }
    }
  },
  methods: {
    // ILL: 230222 para que la longitud del campo zip sea max = 5
    fmt5num(value) {        
        let v=value
        v = v.length > 5 ? v.substr(0,5) : v
        return v
    },
    checkZip (zip) {
      // Hay que validar antes el envio
      this.$http
        .post('/common/zip', { zip: zip })
        .then((response) => {
          const bundle = response.data.bundle
          
          this.stateOption = [bundle.state]
          this.townOption = [bundle.town]
          this.suburbOption = [...bundle.suburbs]

          this.localOptions.state = bundle.state
          this.localOptions.town = bundle.town

          this.stimeOption = bundle.stime
          this.wtimeOption = bundle.wtime
        }).
        catch(error => {
          this.stateOption = []
          this.townOption = []
          this.suburbOption = []

          this.localOptions.state = { k: '',v: '' }
          this.localOptions.town = { k: '',v: '' }
          this.localOptions.suburb = { k: '',v: '' }

          this.stimeOption = null;
          this.wtimeOption = null;
        })
    },
    updateAddress() {
      let business = {
        address: {
          main: {
            street: this.localOptions.street,
            suburb: {
              k: this.localOptions.suburb.k,
              v: this.localOptions.suburb.v
            },
            town: {
              k: this.localOptions.town.k,
              v: this.localOptions.town.v
            },
            state: {
              k: this.localOptions.state.k,
              v: this.localOptions.state.v
            },
            country: {
              k: 'MEX',
              v: 'Mexico'
            },
            meta: {
              sat: {
                country: '',
                state: this.localOptions.state.sat_cve,
                town: '',
                suburb: '',
                st: {
                  m: this.stimeOption ? this.stimeOption.m : 0,
                  a: this.stimeOption ? this.stimeOption.a : '',
                  h: this.stimeOption ? this.stimeOption.h : '',
                  gm: this.stimeOption ? this.stimeOption.gm : -5.0
                },
                wt: {
                  m: this.wtimeOption ? this.wtimeOption.m : 0,
                  a: this.wtimeOption ? this.wtimeOption.a : '',
                  h: this.wtimeOption ? this.wtimeOption.h : '',
                  gm: this.wtimeOption ? this.wtimeOption.gm : -6.0
                }
              }
            },
            zip: this.localOptions.zip
          }
        }
      }

      this.$http.post('/account/address/main/update', { business })
          .then(response => {
            this.$http.post('/s/refresh',{})
            .then(responseRefresh => {
              const session = responseRefresh.data.session
              /**
               * In the meantime we'll use this snippet of code, we need to refactor all points where the session is updated o readed
               */
              localStorage.setItem('session', JSON.stringify(session))

              /** Updates the main session in settings */
              this.$root.$emit('session-refresh',{})

              this.$ability.update(session.account.ability)
                /** Aqui poner la beautiful experience */
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Data Updated'),
                    icon: 'StarIcon',
                    variant: 'success',
                    text: this.$t('Main Address success update'),
                  },
                })
            })
            
          })
          .catch(error => {
            console.group('fg.views.settings.address.main')
            console.error('error',error)
            console.groupEnd()
          })
    }
  },
  setup() {

  const {
      getValidationState,
      resetForm,
    } = formValidation()

  const { t } = useI18nUtils()
    return {
      t,
      getValidationState,
      resetForm,
      
    
    }
    
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
