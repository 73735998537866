<template>
    <div>
      <settings-branches-add-new
        ref="addBranchComponent"
        :is-add-new-branch-sidebar-active.sync="isAddNewBranchSidebarActive"
        @refetch-data="refetchBranchesList"
      />

      <settings-branches-edit
        ref="editBranchComponent"
        :is-edit-branch-sidebar-active.sync="isEditBranchSidebarActive"
        @refetch-data="refetchBranchesList"
      />
  
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
  
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-left justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              disabled
              :placeholder="t('Search...')"
            />
          </b-col>
  
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <template v-if="session.business.address.main.zip === ''">
              <b-button
                variant="danger"
                :to="{ name: 'fg-account-settings'}"
              >
                <span class="text-nowrap">{{ $t('Complete Profile First') }}</span>
              </b-button>
            </template>
            <template v-else>
              <b-button
                variant="primary"
                @click="isAddNewBranchSidebarActive = true"
              >
              <span class="text-nowrap">{{ $t('Add Branch') }}</span>
              </b-button>
            </template>
  
            
          </b-col>
          </b-row>
        </div>
  
        <b-table
          ref="refBusinessBranchesListTable"
          class="position-relative"
          :items="businessBranchesListData"
          :fields="tableColumns"
          :empty-text="$t('No matching records found')"
          primary-key="id"
          responsive="sm"
          show-empty
        >
          <template #head(name)="data">
            <span class="text-secondary" style="width: 30%;">{{ t(data.label) }}</span>
          </template>
  
          <template #head(address)="data">
            <span class="text-secondary" style="width: 70%;">{{ t(data.label) }}</span>
          </template>
  
          <template #head(actions)="data">
            <span class="text-secondary" style="width: 70%;">{{ t(data.label) }}</span>
          </template>
  
          <!-- Column: branch->name -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link :id="`branch-row-${data.item.id}-name`" class="font-weight-bold d-block text-nowrap">
                {{ data.item.name.length > 45 ? data.item.name.substr(0,45) + '. . .' : data.item.name}}
              </b-link>
              <b-tooltip v-if="data.item.name.length > 45"
                class="cursor-pointer"
                :target="`branch-row-${data.item.id}-name`"
              >
                <span style="font-size: 7.5pt;">{{ data.item.name }}</span>
              </b-tooltip>            
              <small class="text-black"> {{ t('Id') + ' : '}} <strong>{{ data.item.id }}</strong></small>&nbsp;&nbsp;<small class="text-black">{{ t('Prefix') + ' : '}} <strong>{{ data.item.prefix }}</strong></small>
            </b-media>
          </template>
  
          <!-- Column: branch->address -->
          <template #cell(address)="data">
            <div class="text-nowrap" style="display: grid;">
              <span class="align-text-top text-capitalize">{{ data.item.add_street }}, {{ data.item.add_suburb }}, {{ data.item.add_town }}</span>
              <span class="align-text-top text-capitalize"><strong>{{ data.item.add_state }}, {{ data.item.add_country }}, {{ data.item.add_zip }}</strong></span>
            </div>
          </template>
  
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-row>
              <b-col cols="1" v-if="data.item.id !== 'MAIN'">
                <feather-icon
                  :id="`branch-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  class="cursor-pointer"
                  size="18"
                  @click="editBranch(data.item.id)"
                />
                <b-tooltip
                  :title="t('Edit')"
                  class="cursor-pointer"
                  :target="`branch-row-${data.item.id}-edit-icon`"
                />
              </b-col>
              <!--<b-col cols="1" v-if="data.item.id !== 'MAIN'">
                <feather-icon
                  :id="`branch-row-${data.item.uuid}-delete-icon`"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  size="18"
                  @click="deleteCfdi(data.item.uuid)"
                />
                <b-tooltip
                  :title="t('Delete')"
                  class="cursor-pointer"
                  :target="`branch-row-${data.item.uuid}-delete-icon`"
                />
              </b-col>-->  
              <b-col cols="6">
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButtonGroup, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, VBTooltip,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import moment from 'moment'
  import { ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
  import { useUtils as useI18nUtils } from '@core/libs/i18n'
  import { avatarText, cFormatter, dFormatter } from '@core/utils/filter'
  //import useCfdiList from './useCfdiList'
  //import cfdiStoreModule from './cfdiStoreModule'
  import SettingsBranchesAddNew from './SettingsBranchesAddNew.vue'
  import SettingsBranchesEdit from './SettingsBranchesEdit.vue'
  //import CfdiListEdit from './CfdiListEdit.vue'
  import { useThrottledRefHistory } from '@vueuse/core'
  
  export default {
    components: {
      SettingsBranchesAddNew,
      SettingsBranchesEdit,
      //CfdiListEdit,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButtonGroup,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BTooltip,
  
      vSelect,
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    mounted() {
      this.session = JSON.parse(localStorage.getItem('session'))
      
      this.$root.$on('session-settings-refresh',(message) => {
        console.log('SettingsBranches session-settings-refresh', message)
        this.session = JSON.parse(localStorage.getItem('session'))
      })
    },
    watch: {
      branchesListData (v) {
        console.log('SettingsBranches watch::mainBranchesListData', v)
        this.businessBranchesListData = v.map((t) => { return {id:t.id,name:t.name,prefix:t.prefix,add_street:t.address.street,add_town:t.address.town.v,add_suburb:t.address.suburb.v,add_state:t.address.state.v,add_country:t.address.country.v,add_zip:t.address.zip}}) ?? []
      },
      $route(to, from) {
        if(!to.query.taxid){
          this.searchQuery = ''
        }
      }
    },
    computed: {
      canCancelMultiple: {
        get() {
          if(!this.isFoliosScope || this.listCfdis.length === 0) return false
          return true
        }
      },
      canUseAsDiscountMultiple: {
        get() {
          if(!this.isFoliosScope || this.listCfdis.length === 0) return false
  
          let currentCustomerTaxid = ''
          let currentCustomerPaymentCurrency = ''
  
          for(let i = 0; i < this.listCfdis.length; i++){
            if(currentCustomerTaxid === '') {
              currentCustomerTaxid = this.listCfdis[i].c_taxid
            } else {
              if(currentCustomerTaxid !== this.listCfdis[i].c_taxid){
                return false
              }
            }
  
            if(currentCustomerPaymentCurrency === '') {
              currentCustomerPaymentCurrency = this.listCfdis[i].wcu
            } else {
              if(currentCustomerPaymentCurrency !== this.listCfdis[i].wcu){
                return false
              }
            }
          }
  
          return true
        }
      }
    },
    props:{
      taxid: String,
      branchesListData: {
        type: Array,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      const USER_APP_STORE_MODULE_NAME = 'fg-business-branches'
  
      let searchQuery = ref('')
      let isAddNewBranchSidebarActive = ref(false)
      let isEditBranchSidebarActive = ref(false)
      let businessBranchesListData = ref([])
  
      let tableColumns = ref([
        { 
          key: 'name', 
          label: 'Branch',
          thStyle: { width: '25%' } 
        },
        { 
          key: 'address', 
          label: 'Address',
          thStyle: { width: '65%' }  
        },
        { 
          key: 'actions', 
          label: 'Actions',
          thStyle: { width: '10%' }  
        }
      ])
      /** lsandoval: 15/12/2023 We import the i18n utilities */
      const { t } = useI18nUtils()
  
      /** lsandoval: 15/12/2023 We add the session to handle emisor data */
      let session = JSON.parse(localStorage.getItem('session'))
  
      const vm = getCurrentInstance().proxy
    
      businessBranchesListData.value = session.business.branches.map((t) => { return {id:t.id,name:t.name,prefix:t.prefix,add_street:t.address.street,add_town:t.address.town.v,add_suburb:t.address.suburb.v,add_state:t.address.state.v,add_country:t.address.country.v,add_zip:t.address.zip}}) ?? []
      
      return {
        // Sidebar
        isAddNewBranchSidebarActive,
        isEditBranchSidebarActive,
        businessBranchesListData,
        searchQuery,
        tableColumns,
        session,
        // i18n
        t,
  
        USER_APP_STORE_MODULE_NAME
      }
    },
    data() {
      return {
        selEmmited: false,
        selMonthCurrent: true,
        selMonthOneBefore: false,
        selMonthTwoBefore: false
      }
    },
    methods: {
      // ILL: 060423 para que la cadena de busqueda se transforme a mayusculas
      fmtUppercaseAll(value) {
          let v = value.toUpperCase()        
          return v
      },
      refetchBranchesList () {
        this.$refs.refBusinessBranchesListTable.refresh()
      },
      editBranch(branchId) {
        this.$refs.editBranchComponent.setCurrentBranch(this.session.business.branches.find((branch) => branch.id === branchId))
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  