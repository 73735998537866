<template>
  <b-card>

    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            rounded
            :src="previewData"
            height="175"
            width="350"
          />
        </b-link>
        
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ t('Upload') }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="loadImage"
        />              
        <!--<b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          {{ t('Reset') }}
        </b-button>-->
        <b-card-text>{{ t('Allowed image') }}</b-card-text>
      </b-media-body>
    </b-media>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="8">
          <b-form-group
            :label="t('Business Name')"
            label-for="account-username"
          >
            <b-form-input
              disabled
              v-model="optionsLocal.name"            
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            :label="t('Tax ID')"
            label-for="account-taxid"
          >
            <b-form-input
              disabled
              v-model="optionsLocal.taxid"
              name="taxid"
            />
          </b-form-group>
        </b-col>
        <b-col sm="8">
          <b-form-group
            :label="t('Email')"
            label-for="account-e-mail"
          >
            <b-form-input
              disabled
              v-model="optionsLocal.email"
              name="email"            
            />

          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            :label="t('Phone')"
            label-for="account-phone"
          >
            <b-form-input
              v-model="optionsLocal.phone"
              name="phone"
              :placeholder="t('Phone Fixed or Mobile')"
              type="number"
              :formatter="fmt10num"
            />
          </b-form-group>
        </b-col>

        <b-col sm="8">
          <b-form-group
            label-for="taxBandList"
            :label="t('TaxPayer Type')"
          >
            <v-select
              id="taxBandList"
              multiple
              v-model="optionsLocal.taxpayer"
              text-field="v"
              label="v"
              :options="taxpayerList.filter(c => c.pt.find(o => o === optionsLocal.type))"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">  
        </b-col>
        
        <b-col sm="8">
          <b-form-group
            :label="t('Website')"
            label-for="account-website"
          >
            <b-form-input
              v-model="optionsLocal.website"
              name="website"              
            />

          </b-form-group>
        </b-col>
        <b-col sm="4">  
        </b-col>

        <!-- alert -->
        <!--<b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert>
        </b-col>-->
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateGeneral"
          >
            {{ t('Save') }}
          </b-button>
<!-- ILL: 080222 SE ELIMINA BOTON DE RESET         
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            {{ t('Reset') }}
          </b-button>-->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import taxpayerList from '@/@catalogs/sat/c_regimenfiscal'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      taxpayerList,
      profileFile: null,
    }
  },
  computed: {
    previewData: function () {
      if(this.previewEl && this.previewEl !== '') {
        if(this.previewEl.startsWith('https')) {
          return this.previewEl + '?rand=' + Date.now()
        }

        return this.previewEl
      } 
      
      return this.optionsLocal.avatar && this.optionsLocal.avatar !== '' && this.optionsLocal.avatar.startsWith('https') ? this.optionsLocal.avatar + '?rand=' + Date.now() : 'https://via.placeholder.com/350x175.png?text=Logo'
    }
  },
  methods: {
    loadImage() {
      //console.log('SettingsGeneral',this.$refs.refInputEl)
      const file = this.$refs.refInputEl.files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.updateAvatar(reader.result)
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    // ILL: 030222 para que la longitud del campo phone sea max = 10
    fmt10num(value) {        
        let v=value
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },    
    updateGeneral() {
      let business = {
        phone: this.optionsLocal.phone,
        taxpayer: this.optionsLocal.taxpayer,
        website: this.optionsLocal.website,
      }

      this.$http.post('/account/general/update', { business })
      .then(response => {
        console.group('fg.views.settings.general')
        console.log('response',response)
        console.groupEnd()

        this.$http.post('/s/refresh',{})
          .then(response => {
            const { session, tokens } = response.data
            /**
             * In the meantime we'll use this snippet of code, we need to refactor all points where the session is updated o readed
             */
            localStorage.setItem('session', JSON.stringify(session))
            localStorage.setItem('tokens', tokens)
            this.$ability.update(session.account.ability)
              /** Aqui poner la beautiful experience */
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('Data Updated'),
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: this.$t('General Data success update'),
                },
              })

              /** Updates the main session in settings */
              this.$root.$emit('session-refresh',{})
          })
      })
      .catch(error => {
        console.group('fg.views.settings.general')
        console.error('error',error)
        console.groupEnd()
      })
    },
    updateAvatar( e ) {
      this.previewEl = e

      let business = {
        avatar: e
      }

      this.$http.post('/account/avatar/update', { business })
      .then(response => {
        console.group('fg.views.settings.general.avatar')
        console.log('response',response)
        console.groupEnd()

        this.$http.post('/s/refresh',{})
          .then(response => {
            const { session, tokens } = response.data
            /**
             * In the meantime we'll use this snippet of code, we need to refactor all points where the session is updated o readed
             */
            localStorage.setItem('session', JSON.stringify(session))
            localStorage.setItem('tokens', tokens)
            this.$ability.update(session.account.ability)
              /** Aqui poner la beautiful experience */
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('Data Updated'),
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: this.$t('Avatar success update'),
                },
              })

              /** Updates the main session in settings */
              this.$root.$emit('session-refresh',{})
          })
      })
      .catch(error => {
        console.group('fg.views.settings.general.avatar')
        console.error('error',error)
        console.groupEnd()
      })
    }

  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const toast = useToast()

    /**const { inputImageRenderer } = useInputImageRenderer(refInputEl, __previewEl)**/
    const { t } = useI18nUtils()

    return {
      refInputEl,
      previewEl,
      t,
      /**inputImageRenderer,      */
    }
  },
}
</script>
