<template>
    <b-sidebar
      id="add-new-branch-sidebar"
      :visible="isAddNewBranchSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      width="600px"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-branch-sidebar-active', val)"
    >
    
      <template #default="{ hide }">
        <div>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ t('Add New Branch') }}
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Branch ID -->
            <validation-provider
              #default="validationContext"
              ref="branchIdFocusv"
              name="BranchId"
              rules="required"
            >
              <div class="box-file">
                <b-form-group
                :label="t('Branch ID')"   
                ref="branchIdFocusg"           
                label-for="branchid"
                :invalid-feedback="t('branchidr')"
                v-b-tooltip.hover.top="t('tbranchid')"
                >
                  <b-form-input
                    id="branchid"
                    ref="branchidFocus"
                    v-model="branchData.id"
                    :autofocus=true
                    :state="getValidationState(validationContext)"
                    :formatter="fmtUppercase"
                    trim            
                    class="content-id-input"
                  />
  
                </b-form-group>
              </div>
            </validation-provider>
  
            <!-- Branch Name -->
            <validation-provider
              #default="validationContext"
              name="BranchName"
              rules="required"
            >
              <b-form-group
                :label="t('Branch Name')"
                label-for="branchName"
                :invalid-feedback="t('bbranchname')"
                v-b-tooltip.hover.top="t('tbranchname')"
              >
                <b-form-input
                  id="branchName"
                  v-model="branchData.name"
                  :state="getValidationState(validationContext)"
                  :formatter="fmtUppercaseAll25"
                  trim
                  class="content-name-input"
                />
  
                <!--<b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>-->
              </b-form-group>
            </validation-provider>
  
            <!-- Branch Prefix -->
            <validation-provider
              #default="validationContext"
              name="branchPrefix"
              rules="required"
            >
              <b-form-group
                :label="t('Branch Prefix')"
                label-for="branchPrefix"
                :invalid-feedback="t('bprefix')"
                v-b-tooltip.hover.top="t('tbranchprefix')"
              >
                <b-form-input
                  id="branchPrefix"
                  v-model="branchData.prefix"
                  :state="getValidationState(validationContext)"
                  :formatter="fmtUppercase3"
                  class="content-prefix-input"
                  trim
                />
              </b-form-group>
            </validation-provider>
  
            <div class="divider my-2">
              <div class="divider-text">
                {{ t('Addressonly') }}              
              </div>                          
            </div>
  
            <!-- ZIP -->
            <validation-provider
              #default="validationContext"
              name="ZIP"
              rules="required|min:5"
            >
              <b-form-group
                :label="t('Zip')"
                label-for="zip"
                :invalid-feedback="t('zipr')"
              >
                <b-form-input
                  id="zip"
                  v-model="branchData.address.zip"
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  :formatter="fmt5num"
                  @change="checkZip(branchData.address.zip)"
                  class="content-id-input"
                />
              </b-form-group>
            </validation-provider>
  
            <!-- Country -->
            <b-form-group
              :label="t('Country')"
              label-for="countryList"            
            >
              <v-select
                id="countryList"
                v-model="branchData.address.country"
                :disabled="branchData.taxid !== 'XEXX010101000'"
                text-field="v"
                label="v"
                :options="countryOption"
              />
            </b-form-group>
  
            <!-- State -->
            <b-form-group
              v-if="branchData.taxid !== 'XEXX010101000'"
              :label="t('State')"
              label-for="stateList"            
            >
              <v-select
                id="stateList"
                disabled
                v-model="branchData.address.state"
                text-field="v"
                label="v"
                :options="stateOption"
              />
            </b-form-group>
  
            <!-- Town -->
            <b-form-group
              v-if="branchData.taxid !== 'XEXX010101000'"
              :label="t('Town')"
              label-for="townList"            
            >
              <v-select
                id="townList"
                disabled
                v-model="branchData.address.town"
                text-field="v"
                label="v"
                :options="townOption"
              />
            </b-form-group>
  
            <!-- Suburb -->
            <b-form-group
              v-if="branchData.taxid !== 'XEXX010101000'"
              :label="t('Suburb')"
              label-for="suburbList"
              v-b-tooltip.hover.top="t('tcolonia')"
            >
              <v-select
                id="suburbList"
                v-model="branchData.address.suburb"
                text-field="v"
                label="v"
                :options="suburbOption"
              />
            </b-form-group>
  
            <!-- Street -->
            <b-form-group
              :label="branchData.taxid !== 'XEXX010101000' ? t('Street') : t('Direccion')"
              label-for="address-street"
            >
              <b-form-input
                id="street"
                :formatter="fmtUppercaseAll"
                v-model="branchData.address.street"
                
              />
            </b-form-group>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="!isSaveEnabled"
              >
                {{ t('Add') }}   
                
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
        </div>
      </template>
    </b-sidebar>
  </template> 
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, VBTooltip, BInputGroupAppend, BInputGroup,BMedia,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { useUtils as useI18nUtils } from '@core/libs/i18n'
  import { ref } from '@vue/composition-api'
  import { required, alphaNum, email, catalog } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import invoiceuseList from '@/@catalogs/sat/c_usocfdi'
  import taxpayerList from '@/@catalogs/sat/c_regimenfiscal'
  import countryList from '@/@catalogs/sat/c_pais'
  import store from '@/store'
  import { BIcon, BTooltip } from 'bootstrap-vue'
  
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BInputGroupAppend,
      BMedia,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BIcon,
      BTooltip
    },
    directives: {
      'b-tooltip': VBTooltip,
      Ripple,
    },
    model: {
      prop: 'isAddNewBranchSidebarActive',
      event: 'update:is-add-new-branch-sidebar-active',
    },
    props: {
      isAddNewBranchSidebarActive: {
        type: Boolean,
        required: true,
      },
    },
    
    computed: {
      isSaveEnabled: {
        get() {
          return this.branchData.id !== '' &&
                      this.branchData.name !== '' &&
                      this.branchData.prefix !== '' &&
                      this.branchData.address.zip !== '' &&
                      this.branchData.address.suburb.k !== '' &&
                      this.branchData.address.street !== ''
        }
      }
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        catalog,
        stateOption: [],
        townOption: [],
        suburbOption: [],
        countryOption: countryList,
        invoiceuseList,
        taxpayerList,
        taxIdValid: false,
        stimeOption: null,
        wtimeOption: null,
        // uploading file feature
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'photos',
        isUploading: false,
        uploadingError: false,
        onUpdateBusy: false
      }
    },
    setup(props, { emit }) {
      
      const blankBranchData = {
        id: '', // Branch Id
        name: '', // Branch Name
        prefix: '', // Branch Serial Prefix
        address: {
          street: '',
          suburb: {
            k: '',
            v: ''
          },
          town: {
            k: '',
            v: ''
          },
          state: {
            k: '',
            v: ''
          },
          country: countryList.find(a => a.k === 'MEX'),
          meta: {
            sat: {
              country: '',
              state: '',
              town: '',
              suburb: '',
              st: {
                m: 0,
                a: '',
                h: '',
                gmt: 0
              },
              wt: {
                m: 0,
                a: '',
                h: '',
                gmt: 0
              }
            }
          },
          zip: '',
        }
      }
  
      /** lsandoval: 26/01/2022 We import the i18n utilities */
      const { t } = useI18nUtils()
      /** lsandoval: 25/02/2022 We import the toast utilities */
      const toast = useToast()
  
      const branchData = ref(JSON.parse(JSON.stringify(blankBranchData)))
      const resetBranchData = () => {
        branchData.value = JSON.parse(JSON.stringify(blankBranchData))
      }
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetBranchData)
  
      
  
      return {
        branchData,
        
        refFormObserver,
        getValidationState,
        resetForm,
  
        t,
      }
    },
    methods: {
      fmtUppercase(value) {
          let v = value.toUpperCase()
          v = v.length > 10 ? v.substr(0,10) : v
          return v
      },
      fmtUppercase10(value) {
          let v = value.toUpperCase()
          v = v.length > 10 ? v.substr(0,10) : v
          return v
      },
      fmtUppercase3(value) {
          let v = value.toUpperCase()
          v = v.length > 3 ? v.substr(0,3) : v
          return v
      },             
      // ILL: 110322 para que la longitud del campo nombre se transforme a mayusculas
      fmtUppercaseAll(value) {
          let v = value.toUpperCase()        
          return v
      },
      // ILL: 110322 para que la longitud del campo nombre se transforme a mayusculas Y sea de 25 posiciones
      fmtUppercaseAll25(value) {
          let v = value.toUpperCase()        
          v = v.length > 25 ? v.substr(0,25) : v
          return v
      },          
      // ILL: 270122 para que la longitud del campo phone sea max = 10
      fmt10num(value) {        
          let v=value
          v = v.length > 10 ? v.substr(0,10) : v
          return v
      },
      // ILL: 270122 para que la longitud del campo zip sea max = 5
      fmt5num(value) {        
          let v=value 
          v = v.length > 5 ? v.substr(0,5) : v
          return v
      },    
      checkZip (zip) {
        this.$http
          .post('/common/zip', { zip: zip })
          .then((response) => {
            const bundle = response.data.bundle
            
            this.stateOption = [bundle.state]
            this.townOption = [bundle.town]
            this.suburbOption = [...bundle.suburbs]
  
            this.branchData.address.state = bundle.state
            this.branchData.address.town = bundle.town
  
            this.branchData.address.meta.sat.st = bundle.stime
            this.branchData.address.meta.sat.wt = bundle.wtime
  
            this.stimeOption = bundle.stime
            this.wtimeOption = bundle.wtime
          }).
          catch(error => {
            this.stateOption = []
            this.townOption = []
            this.suburbOption = []
  
            this.branchData.address.state = { k: '',v: '' }
            this.branchData.address.town = { k: '',v: '' }
            this.branchData.address.suburb = { k: '',v: '' }
            this.branchData.address.meta.sat.st = { m: 0, a: '', h: '', gmt: 0}
            this.branchData.address.meta.sat.wt = { m: 0, a: '', h: '', gmt: 0 }
  
            this.stimeOption = null;
            this.wtimeOption = null;
          })
      },
      onSubmit () {
        
        this.onUpdateBusy = true
        this.$http.post('/account/branch/save', { business : { branch: this.branchData } })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Data Updated'),
                icon: 'StarIcon',
                variant: 'success',
                text: this.$t('Branch Added Successfully'),
              },
            })
            
            /** Updates the main session in settings */
            this.$root.$emit('session-refresh',{})
            this.isAddNewBranchSidebarActive = false
  
            this.onUpdateBusy = false
  
          })
          .catch(error => {
            console.group('fg.views.settings.general')
            console.error('error',error.response)
            console.groupEnd()
  
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Error while uploading Business Data'),
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                text: this.$t('The branch office cant be created, because: '),
                //text: this.$t('The branch office cant be created, because: ') + error.response.data.message, ILL: REMOVE 11/01/23
              },
            })
  
            this.onUpdateBusy = false
          })
  
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  